<template>
  <div class="m-business-box">
    <bannerStatic :num="2" />
    <div class="content-box">
      <div class="product-box">
         <h4 class="titleStyle">产品介绍</h4>
         <div class="info-box">
            <a-tabs
              class="tab"
              defaultActiveKey="1"
              @prevClick="callback"
              @nextClick="callback"
              @change="typechangeFunc"
            >
              <a-tab-pane v-for="i in typeList" :tab="i.moduleName" :key="i.moduleId">
                <a-collapse accordion expandIconPosition="right">
                    <a-collapse-panel v-for="item in typechange" :key="item.id" :header="item.name" >
                      <span slot="extra" >
                        <img class="normal" :src="item.img" alt="">
                      </span>
                      <p>{{item.txt}}</p>
                      <b @click="jumpPage(item.id)">了解更多<a-icon type="right" /></b>
                    </a-collapse-panel>
                  </a-collapse>
              </a-tab-pane>
            </a-tabs>
         </div>
      </div>
        <div class="case-box">
            <h4 class="titleStyle">案例展示</h4>
            <div class="info-box">
                <ul>
                  <li v-for="item in solutionList" :key="item.id" @click="jumpPage(item.id)">
                    <div class="img-box">
                     <img :src="item.logoUrl" alt="" >
                    </div>
                    <div class="txt-box">
                         <p class="name">{{item.name}}</p>
                    </div>
                  </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import { getProductCase,getProductType } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'Cooperation',
  components:{
    bannerStatic,
  },
  data(){
    return {
      newsImg,
      solutionList:[],
      productList:[],
      typeList:[
        {
          moduleName:"全部",
          moduleId:0
        }
      ],
      nowType:0,
      isNull:false
    }
  },
   computed: {
    typechange(){
      let _this=this
      let arr=[]
      if(this.nowType==0){
        arr=this.productList
      }else{
        arr=this.productList.filter(function (number) {
          return number.moduleId ==_this.nowType
        })
      }
      this.isNull=arr.length>0?false:true
      return arr
    }
  },
   mounted() {
      window.addEventListener("scroll", this.handleScroll);
      this.getType().then(
       this.getData()
      )
  },
  methods:{
    jumpPage(id){
        this.$router.push({path:`/MBusinessPage/${id}`})
    },
    callback(val) {
      console.log(val);
    },
    typechangeFunc(key){
      this.nowType=key
    },
    getType(){
      let _this=this
      return getProductType({type:1}).then(res=>{
         if (res.state) {
          if(res.data.length>0){
            res.data.map((item)=>{
               _this.typeList.push(item)
            })
          }
         }
      })
    },
    getData(){
      let _this=this
      _this.productList=[]
      _this.solutionList=[]
      _this.isNull=false
      return getProductCase().then(res => {
          if (res.state) {
              if(res.data.length>0){
                  res.data.map((item)=>{
                      if(item.productType==1&&item.isEnable){
                          let imgUrl=item.productCaseImages&&item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                          let hoverImgUrl=item.productCaseImages&&item.productCaseImages[1]?_this.newsImg+item.productCaseImages[1].imageUrl:''
                            _this.productList.push({
                                id:item.productID,
                                name:item.productName,
                                txt:item.productContent,
                                img:imgUrl,
                                img2:hoverImgUrl,
                                moduleId:item.moduleId
                            })
                      }
                      if(item.productType==2&&item.isEnable){
                            let imgurl=item.productImageUrl?_this.newsImg+item.productImageUrl:''
                            let logoUrl=item.productCaseImages[0]?_this.newsImg+item.productCaseImages[0].imageUrl:''
                            _this.solutionList.push({
                                id:item.productID,
                                name:item.productName,
                                txt:item.productContent,
                                imgurl:imgurl,
                                logoUrl:logoUrl
                            })
                      }
                  })
                  _this.isNull= _this.productList.length>0?false:true
              }else{
                  _this.productList=[]
                  _this.solutionList=[]
                  _this.isNull=false
              }
          }
      })
    }
  },
   beforeDestroy() {
  },
}
</script>
<style lang="less" >
.m-business-box{
  .content-box{
    .titleStyle {
      font-size: .48rem /* 36/75 */;
      margin-bottom: 0;
      background: #fff;
     padding:.933333rem /* 70/75 */ 0 .666667rem /* 50/75 */;

    }
    .product-box{
      background: #fff;
      .info-box{
        .ant-tabs-bar{
          margin-bottom: 0;
          text-align: left;
        }
        .ant-tabs-nav{
         .ant-tabs-tab{
           margin-right: 0;
           font-size: .4rem /* 30/75 */;
           padding: 0 16px 6px;
         } 
        .ant-tabs-ink-bar{
            opacity: 0;
          }
        }
        .ant-collapse{
            border: none;
            border-radius: 0;
            .ant-collapse-item{
              border-radius: 0;
              border:none;
              text-align: left;
              .ant-collapse-header{
                border-radius: 0;
                background: #fff;
                border-bottom: 1px solid #e4e4e4;
                height: 1.053333rem /* 79/75 */;
                box-sizing: content-box;
                line-height: 1.053333rem /* 79/75 */;
                overflow: hidden;
                font-size: .373333rem /* 28/75 */;
              }
              .ant-collapse-content{
                border-top: 0;
                background: #f7fbfe;
                p{
                  font-size: .346667rem /* 26/75 */;
                  color: #333333
                }
                b{
                  font-size: .32rem /* 24/75 */;
                  color: #2690f3;
                  i{
                    font-size: .266667rem /* 20/75 */;
                  }
                }
              }
              .ant-collapse-extra {
                  float: left !important;
                  margin-right:.466667rem /* 35/75 */;
                  span{
                    width:  .8rem /* 60/75 */;
                    height: 1.053333rem /* 79/75 */;
                    overflow: hidden;
                    display:inline-block;
                    text-align: center;
                    img{
                      max-height: 100%;
                      max-width: 100%;
                    }
                  }
              }
            }
        }
      }
    }
    .case-box{
      .info-box{
        li{
          	width: 9.2rem /* 690/75 */;
            height: 5.6rem /* 420/75 */;
            background-color: #fbfeff;
            box-shadow: 0px 0px .2rem /* 15/75 */ 0px  rgba(27, 81, 241, 0.15);
            border-radius: 10px;
            margin: 1.066667rem /* 80/75 */ .4rem /* 30/75 */;
            box-sizing: border-box;
            overflow: hidden;
            .img-box{
              height: 4.6rem /* 345/75 */;
              background: #4285f4;
              overflow: hidden;
              img{
                object-fit: cover;
                width: 100%;
              }
            }
            .txt-box{
              padding:.2rem /* 15/75 */ .266667rem /* 20/75 */ 0;
              .name{
                font-size: .373333rem /* 28/75 */;
                margin-bottom: 0;
              }
            }
        }
      }
    }
  }
  a,a:visited,a:link{
    text-decoration: none;
    color: #333333;
  }
}

</style>